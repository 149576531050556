import { FormEvent, useState } from 'react';
import {
  IVaccinationInterface,
  IWardDTO,
  defaultVaccinationData,
} from '../../../interface/VaccinationInterface';
import { isFormValidated } from '../../../utils/formUtils';
import ButtonLoader from '../../common/ButtonLoader';
import { addToast } from '../../../utils/toastNotifications';
import AddWardModal from './AddWardModal';
import axios from 'axios';
import { endpoints } from '../../../utils/URL';
import { Link } from 'react-router-dom';
import { VitalScreenTypes } from '../../../utils/mappings';

const VaccinationForm = () => {
  const [data, setData] = useState<IVaccinationInterface>(defaultVaccinationData);
  const [wards, setWards] = useState<IWardDTO[]>([]);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [screen, setScreen] = useState(VitalScreenTypes.bioData);

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setData({
      ...data,
      [input]: value,
    });
  };

  const addWardData = (wardData: IWardDTO) => {
    //if medicationId > 0 then we are editing
    //else we are adding
    let wardId = wards.length + 1;
    const lastMedication = wards[wards.length - 1];

    if (lastMedication) {
      wardId = lastMedication.id + 1;
    }

    wardData.id = wardId;
    wards.push(wardData);
    setWards(wards);
  };

  const deleteWardData = (id: number) => {
    const existingWards = wards.filter((x) => x.id !== id);
    wards.length = existingWards.length;
    //add updated medication
    setWards(existingWards);
    addToast('Bio data deleted', 'success');
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const previousBtn = (event: any) => {
    event.preventDefault();
    setScreen(VitalScreenTypes.bioData);
  };

  const submitData = async (event: FormEvent) => {
    if (!isFormValidated('biodata-form')) {
      return;
    }

    event.preventDefault();

    if (wards.length === 0) {
      addToast('Please add data for at least one female child', 'warning');
      return;
    }

    event.preventDefault();

    data.wards = wards;
    const url = `${endpoints.vaccination.mainUrl}`;
    try {
      setLoading(true);

      await axios.post(url, data);
      addToast('Form submitted successfully', 'success');
      setWards([]);
      setData(defaultVaccinationData);
      setScreen(VitalScreenTypes.feedback);
    } catch (error) {
      addToast('An error occurred when saving the data. Please try again', 'error');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const wardDataModal = (
    <AddWardModal close={toggleShowModal} show={showModal} addWardData={addWardData} />
  );

  return (
    <section className='pt-0 row justify-content-center'>
      {screen !== VitalScreenTypes.feedback && (
        <section className='mt-5 col-xl-5 col-lg-7 col-md-8 col-sm-10 content-section pb-5 mb-5 card'>
          <div className='text-center'>
            <img
              src={require('../../../assets/images/vitals/logo.svg').default}
              className='img-fluid w-25'
              alt=''
            />
          </div>
          <div>
            <h3 className='text-center mt-4 fw-500'>Engagement Log</h3>
            <div className='row justify-content-center content-section mt-1'>
              <div className='rounded-2'>
                <div id='vaccination-form'>
                  <fieldset disabled={loading}>
                    {/* BIO DATA */}
                    {screen === VitalScreenTypes.bioData && (
                      <form id='biodata-form'>
                        <div className='form-group'>
                          <label className='form-label'>Full name of Caregiver</label>
                          <input
                            className='form-control vital-form-control'
                            value={data.fullName}
                            required
                            onChange={handleChange('fullName')}
                          />
                        </div>

                        <div className='form-group mt-4'>
                          <label className='form-label'>Phone Number of Caregiver</label>
                          <input
                            className='form-control  vital-form-control'
                            value={data.phoneNumber}
                            onChange={handleChange('phoneNumber')}
                            pattern='[0-9]{10}|[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                          />
                        </div>
                        <div className='mt-5'>
                          <h6 className='align-content-center fs-20 fw-500'>
                            Girl Child's Information
                            <span className='float-end' style={{ marginTop: '-5px' }}>
                              <button
                                type='button'
                                className='btn btn-secondary btn-sm text-white vitals-bg-primary'
                                onClick={toggleShowModal}
                              >
                                Add <span className='bi bi-plus'></span>
                              </button>
                            </span>
                          </h6>
                        </div>
                        <div className='table-responsive'>
                          {wards.length > 0 ? (
                            <table className='table table-hover'>
                              <thead>
                                <tr>
                                  <th>Age</th>
                                  <th>In school?</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {wards.map((ward) => (
                                  <tr key={ward.id}>
                                    <td className='w-75'>{ward.ageRange}</td>
                                    <td className='w-25'>
                                      {ward.isAttendingSchool ? 'Yes' : 'No'}
                                    </td>
                                    <td className='w-auto'>
                                      <span
                                        className='pointer bi bi-trash text-danger'
                                        title='delete'
                                        onClick={() => deleteWardData(ward.id)}
                                      ></span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            'No data saved'
                          )}
                        </div>
                        <label htmlFor='question-4' className='label-section rounded-1 p-3 mt-4'>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              checked={data.commitedToVaccination}
                              onChange={handleChange('commitedToVaccination')}
                              id='question-4'
                            />
                            <label className='form-check-label' htmlFor='question-4'>
                              Is the caregiver committed to taking the ward to a nearby health
                              facility for HPV vaccination?
                            </label>
                          </div>
                        </label>
                        <label htmlFor='question-3' className='label-section rounded-1 p-3 mt-4'>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              checked={data.willingToDiscussVaccine}
                              onChange={handleChange('willingToDiscussVaccine')}
                              id='question-3'
                            />
                            <label className='form-check-label' htmlFor='question-3'>
                              Is the caregiver willing to chat with someone from the VITAL team
                              about the HPV vaccine?
                            </label>
                            <div className='subtext mt-2'></div>
                          </div>
                        </label>
                        <div className='form-group mt-4'>
                          <label className='form-label'>Additional Notes</label>
                          <textarea
                            className='form-control'
                            rows={5}
                            value={data.additionalNotes}
                            onChange={handleChange('additionalNotes')}
                          ></textarea>
                        </div>
                        <div className='mt-4 row justify-content-end'>
                          <div className='col-sm-6 d-grid mt-3'>
                            <Link
                              to={'/vaccinations/home'}
                              type='button'
                              className='btn btn-outline-secondary vitals-bg-white fs-18 fw-500 vitals-text-primary vitals-border-primary px-0'
                            >
                              <span className='bi bi-house me-2'></span>
                              Back Home
                            </Link>
                          </div>
                          <div className='col-sm-6 d-grid mt-3'>
                            <button
                              onClick={submitData}
                              className='btn btn-secondary text-white vitals-bg-primary fs-18 fw-500 px-4'
                            >
                              Submit
                              {loading && <ButtonLoader />}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          {wardDataModal}
        </section>
      )}
      {screen === VitalScreenTypes.feedback && (
        <section className='pt-5 content-section pb-5 mb-5 card'>
          <div className='text-center'>
            <img
              src={require('../../../assets/images/vitals/logo.svg').default}
              className='img-fluid w-10'
              alt=''
            />
          </div>
          <div className='mt-5 text-center pb-200'>
            <img
              src={require('../../../assets/images/vitals/clap.svg').default}
              className='img-fluid'
              alt=''
            />
            <div className='fs-40 fw-700'>Great Job</div>
            <div className='fs-20 fw-400'>you are helping secure the future of our girls</div>
            <div className='mt-3 fs-20 fw-400'>Are you willing to fill another form?</div>
            <div className='mt-4 row justify-content-center'>
              <div className='col-md-4'>
                <div className='row justify-content-center'>
                  <div className='col-5 d-grid mt-3'>
                    <button
                      onClick={previousBtn}
                      className='btn btn-secondary text-white vitals-bg-primary fs-18 fw-500 px-4'
                    >
                      Yes
                    </button>
                  </div>
                  <div className='col-5 d-grid mt-3'>
                    <Link
                      to={'/vaccinations/home'}
                      type='button'
                      className='btn btn-outline-secondary vitals-bg-white fs-18 fw-500 vitals-text-primary vitals-border-primary px-5'
                    >
                      No
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

export default VaccinationForm;
